import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_Hero } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import { Content } from "components/Content";
import { Button } from "components/Button";
interface HeroProps extends Page_Flexiblecontent_Blocks_Hero {
  className?: string;
}

const Hero = ({
  className,
  variant,
  title,
  ctas,
  content,
  heroSlides,
  bannerText,
  bannerCtas,
}: HeroProps) => {
  const isPrimary = variant === "primary";
  const isBasic = variant === "secondary";

  return (
    <section id={`hero`} className={`${className ?? ``} ${!isPrimary && "mb-4"}`}>
      {isBasic && (
        <div className={`flex flex-col items-center justify-center gap-4`}>
          {title && <h1>{title}</h1>}
          {content && <Content className={`font-sans text-lg`} content={content} />}
          {ctas && ctas.length > 0 ? (
            <div className={`flex gap-4`}>
              {ctas.map((cta, index) => (
                <a
                  key={index}
                  href={cta?.link?.url || ""}
                  target={cta?.link?.target || "_self"}
                  className={`btn ${cta?.type}`}
                >
                  {cta?.link?.title}
                </a>
              ))}
            </div>
          ) : null}
        </div>
      )}
      {isPrimary && (
        <div className={`relative flex items-center justify-center`}>
          <div className={`absolute bottom-0 z-10 w-full bg-light-blue flex flex-col md:flex-row items-center justify-center gap-8 p-4`}>
            {bannerText && <h1 className={`hidden md:block font-body text-white text-lg text-center`}>{bannerText}</h1>}
            {bannerCtas && bannerCtas.length > 0 ? (
              <div className={`flex gap-4`}>
                {bannerCtas.map((cta, index) => (
                  <Button
                    key={index}
                    href={cta?.link?.url || ""}
                    target={cta?.link?.target || "_self"}
                    type={cta?.type ?? "default"}
                  >
                    {cta?.link?.title}
                  </Button>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;

Hero.fragments = {
  entry: gql`
    fragment HeroFragment on Page_Flexiblecontent_Blocks_Hero {
      __typename
      variant
      title
      content
      ctas {
        link {
          title
          target
          url
        }
        type
      }
      bannerText
      bannerCtas {
        link {
          title
          target
          url
        }
        type
      }
      heroSlides {
        isFinalSlide
        finalSlideLogo {
          ...MediaItemFragment
        }
        title
        content
        mediaSide
        imageVideo
        image {
          ...MediaItemFragment
        }
        finalSlideSecondImage {
          ...MediaItemFragment
        }
        video {
          mediaItemUrl
          mimeType
        }
        backgroundColor
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
