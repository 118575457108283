import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_News } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import { Content } from "components/Content";
import Image from "next/image";

interface NewsProps extends Page_Flexiblecontent_Blocks_News {
  className?: string;
}

const News = ({ className, sectionId, columns, gridItems }: NewsProps) => {
  const gridColumns = columns || 2;
  const notOneColumn = gridColumns !== 1;

  let gridClass = "";
  if (columns === 1) {
    gridClass = "grid-cols-1";
  }
  if (columns === 2) {
    gridClass = "grid-cols-1 sm:grid-cols-2";
  }
  if (columns === 3) {
    gridClass = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3";
  }
  if (columns === 4) {
    gridClass = "grid-cols-1 sm:grid-cols-2 md:grid-cols-4";
  }

  return (
    <section id={sectionId ?? ``} className={`${className ?? ``}`}>
      <div
        className={`max-w-[1300px] mx-auto grid ${gridClass} gap-4 my-4 px-4 pt-14`}
      >
        {gridItems &&
          gridItems.map((item, index) => {
            if (item) {
              const { link, title, content, image } = item;
              return (
                <div key={index} className={`mx-auto md:max-w-[80%]`}>
                  <Image
                    className={`w-full h-auto mb-5`}
                    src={image?.sourceUrl || ""}
                    alt={image?.altText || ""}
                    width={image?.mediaDetails?.width || 0 }
                    height={image?.mediaDetails?.height || 0 }
                  />
                  <a
                    className={`group text-med-blue flex flex-col gap-4 transition duration-300 ease-in-out max-md:text-center`}
                    href={link?.url || ""}
                    target={link?.target || "_self"}
                  >
                    {title && (
                      <h3
                        className={`font-body font-semibold text-xl md:text-2xl`}
                      >
                        {title}
                      </h3>
                    )}
                    {content && (
                      <Content
                        className={`font-body text-md md:text-lg`}
                        content={content}
                      />
                    )}
                    <p
                      className={`font-body text-md md:text-lg text-light-blue font-bold`}
                    >
                      Read More &#62;
                    </p>
                  </a>
                </div>
              );
            }
          })}
      </div>
    </section>
  );
};

export default News;

News.fragments = {
  entry: gql`
    fragment NewsFragment on Page_Flexiblecontent_Blocks_News {
      __typename
      sectionId
      columns
      gridItems {
        image {
          ...MediaItemFragment
        }
        link {
          url
          target
        }
        title
        content
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
