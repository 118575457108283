import { Button } from "components/Button";
import { FeaturedImage } from "components/FeaturedImage";
import { Form } from "components/Form";
import { NavigationMenu } from "components/NavigationMenu";
import { AcfLink, Acf_GoogleMap, MediaItem, MenuItem } from "graphql";
import Link from "next/link";
import Logo from "public/logo.svg";
import { BsInstagram, BsTwitterX } from "react-icons/bs";
import { FaFacebookF, FaYoutube, FaTiktok, FaLinkedin } from "react-icons/fa";

export interface FooterProps {
  logo: MediaItem;
  menuItems: MenuItem[];
  phoneNumber: AcfLink;
  address: Acf_GoogleMap;
  email: AcfLink;
  ctaFooter: AcfLink;
}

const Footer = ({
  logo,
  menuItems,
  phoneNumber,
  address,
  email,
  ctaFooter,
}: FooterProps) => {

  return (
    <footer className={`font-body text-med-blue mt-12`}>
      <div
        className={`max-w-[1300px] px-4 py-8 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:justify-between border-t-[1px] border-grey`}
      >
        {/* flex flex-col flex-wrap md:flex-row */}
        {/* Logo */}
        <div
          className={`flex flex-col flex-wrap items-start justify-center md:justify-start gap-2 sm:p-4`}
        >
          {logo ? (
            <FeaturedImage image={logo} className={`mb-6 max-md:mx-auto`} />
          ) : (
            <a
              href="https://noisytrumpet.com"
              className="title-font mb-4 flex items-center font-medium text-gray-900 md:mb-0"
            >
              {`NT Headless Site Template`}
            </a>
          )}
          <div className={`lg:hidden flex flex-row max-md:mx-auto gap-3 mb-6`}>
            <a
              href="https://www.facebook.com/sanantonioareafoundation/"
              target="_blank"
            >
              <FaFacebookF
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a href="https://www.instagram.com/saafdn/" target="_blank">
              <BsInstagram
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a href="https://twitter.com/SAAFdn" target="_blank">
              <BsTwitterX
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a href="https://www.tiktok.com/@saafdn" target="_blank">
              <FaTiktok
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/san-antonio-area-foundation/"
              target="_blank"
            >
              <FaLinkedin
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a href="https://www.youtube.com/user/saafdn/" target="_blank">
              <FaYoutube
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
          </div>
          <div className={`hidden md:flex flex-col`}>
            <p className={`flex flex-row gap-2 mb-3`}>
              Phone:{" "}
              <a
                href={phoneNumber.url ?? ``}
                className="underline"
              >
                {phoneNumber.title}
              </a>
            </p>
            <p className={`flex flex-row gap-2 mb-3`}>
              Email:{" "}
              <a href={email.url ?? ``} className="underline">
                {email.title}
              </a>
            </p>
            <p className={`flex flex-row gap-2`}>
              Hours: Mon.-Thu., 8:30 a.m. -<br />
              4:30 p.m. Fri., 8:30 a.m. - 4 p.m.
            </p>
          </div>
        </div>
        {/* Menu */}
        <nav className="grid sm:grid-cols-2 items-center justify-center md:justify-start gap-4 h-full max-md:text-center">
          {menuItems.map(({ path, label, target }) => (
            <Link
              key={path}
              href={path ?? "/"}
              target={target ?? "_self"}
              className={`font-body font-semibold hover:text-red text-med-blue px-2 py-4 transition duration-300 ease-in-out min-w-[150px]`}
            >
              {label}
            </Link>
          ))}
          {ctaFooter ? (
            <Button
              className={`px-2 ml-0 max-md:justify-center`}
              type="green-text-link"
              href={ctaFooter.url ?? ``}
              target={ctaFooter.target ?? "_self"}
            >
              {ctaFooter.title}
            </Button>
          ) : null}
        </nav>
        <div className={`md:max-lg:col-span-2`}>
          <Form
            className={`md:my-[0!important]`}
            title={`Sign Up for Our Newsletter`}
            form={2}
          />
          <div className={`hidden lg:flex flex-row gap-3`}>
            <a
              href="https://www.facebook.com/sanantonioareafoundation/"
              target="_blank"
            >
              <FaFacebookF
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a href="https://www.instagram.com/saafdn/" target="_blank">
              <BsInstagram
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a href="https://twitter.com/SAAFdn" target="_blank">
              <BsTwitterX
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a href="https://www.tiktok.com/@saafdn" target="_blank">
              <FaTiktok
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/san-antonio-area-foundation/"
              target="_blank"
            >
              <FaLinkedin
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
            <a href="https://www.youtube.com/user/saafdn/" target="_blank">
              <FaYoutube
                className={`text-2xl text-med-blue hover:text-red transition duration-300 ease-in-out`}
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
