import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_Impact } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import { Content } from "components/Content";
import CountImpact from "./Fragments/CountImpact";
import ImageImpact from "./Fragments/ImageImpact";

interface ImpactProps extends Page_Flexiblecontent_Blocks_Impact {
  className?: string;
}

const Impact = ({
  className,
  sectionId,
  varient,
  innerSectionTitle,
  innerSectionContent,
  innerSectionCtas,
  calloutContent,
  calloutBackground,
  verticalText,
  countupBlocks,
  imageBlocks,
}: ImpactProps) => {
  const isCountup = varient === "countup";
  const isImgGrid = varient === "img-grid";

  return (
    <section id={sectionId ?? ``} className={`${className ?? ``} my-4`}>
      {isCountup && (
        <CountImpact
          innerSectionTitle={innerSectionTitle}
          innerSectionContent={innerSectionContent}
          innerSectionCtas={innerSectionCtas}
          calloutContent={calloutContent}
          calloutBackground={calloutBackground}
          countupBlocks={countupBlocks}
        />
      )}
      {isImgGrid && (
        <ImageImpact
          innerSectionTitle={innerSectionTitle}
          innerSectionContent={innerSectionContent}
          innerSectionCtas={innerSectionCtas}
          verticalText={verticalText}
          imageBlocks={imageBlocks}
        />
      )}
    </section>
  );
};

export default Impact;

Impact.fragments = {
  entry: gql`
    fragment ImpactFragment on Page_Flexiblecontent_Blocks_Impact {
      __typename
      sectionId
      varient
      innerSectionTitle
      innerSectionContent
      innerSectionCtas {
        link {
          title
          target
          url
        }
        type
      }
      calloutContent
      calloutBackground
      verticalText
      countupBlocks {
        isDollarAmount
        isAbbreviated
        number
        amountFor
        background
      }
      imageBlocks {
        cause
        causeLink {
          title
          target
          url
        }
        image {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
