import React from "react";
import Slideshow from "./Fragments/Slideshow";

const IntroAnimation = () => {
  const slides = [
    {
      title: `Celebrating <span>60 Years</span> as YOUR most
      impactful and trusted philanthropic partner.`,
      description: `Over $800 Million in Impactful Grants and Scholarships`,
      mediaUrl: `https://areacms.mysites.io/wp-content/uploads/2024/04/60th-logo-animation-white.gif`,
      isVideo: false,
    },
    {
      title: `Transformative Community Engagement for the Past <span>60 Years</span>.`,
      description: `Addressing Social Challenges Through Diverse Initiatives`,
      mediaUrl: `http://areacms.mysites.io/wp-content/uploads/2024/05/EquityFellows-lightenend.mp4`,
      isVideo: true,
    },
    {
      title: `Pioneering Philanthropy for the Past <span>60 Years</span>.`,
      description: `Tailored Solutions for Managing Charitable Funds For Individuals and Organizations`,
      mediaUrl: `http://areacms.mysites.io/wp-content/uploads/2024/05/BrightonKids-lightened.mp4`,
      isVideo: true,
    },
    {
      leftMediaUrl: ``,
      rightMediaUrl: `/images/woman-two-kids.webp`,
      isLast: true,
    },
  ];

  return (
    <section className={`relative w-full h-screen z-0`}>
      <Slideshow slides={slides} speed={5000} />
      <div
        className={`absolute bottom-0 left-0 right-0 h-auto lg:h-[90px] bg-light-blue flex flex-col lg:flex-row items-center justify-center z-20 px-4 lg:px-0`}
      >
        <span className={`text-white font-body font-bold py-4 lg:py-0`}>
          The Most Trusted Philanthropic Partner in San Antonio and South
          Central Texas
        </span>
        <div
          className={`flex flex-row justify-center items-center lg:ml-20 pb-4 lg:pb-0`}
        >
          <a
            className={`w-max rounded-full bg-green border-green border-2 py-2 px-10 font-bold text-black font-body uppercase transition duration-300 ease-in-out hover:shadow-lg hover:bg-white hover:border-white flex flex-row gap-2 items-center lg:ml-4`}
            href="https://saafdn.fcsuite.com/erp/donate/list"
            target="_blank"
          >
            Donate
          </a>
          <a
            className={`w-max rounded-full bg-med-blue border-med-blue border-2 py-2 px-8 font-bold text-white font-body uppercase transition duration-300 ease-in-out hover:shadow-lg hover:bg-white hover:border-white hover:text-black flex flex-row gap-2 items-center ml-4`}
            href="https://saafdn.org/connect-with-us/"
            target="_blank"
          >
            Get in Touch
          </a>
        </div>
      </div>
    </section>
  );
};

export default IntroAnimation;
