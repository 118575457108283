import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_IconGrid } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Image from "next/image";
import { Content } from "components/Content";
import { getBackgroundColor } from "utilities/getColor";

interface IconGridProps extends Page_Flexiblecontent_Blocks_IconGrid {
  className?: string;
}

const IconGrid = ({
  className,
  sectionId,
  columns,
  gridItems,
}: IconGridProps) => {
  const gridColumns = columns || 1;

  let gridClass = "";
  if(gridColumns === 1) {
    gridClass = "grid-cols-1";
  } else if(gridColumns === 2) {
    gridClass = "grid-cols-1 xs:grid-cols-2";
  } else if(gridColumns === 3) {
    gridClass = "grid-cols-1 xs:grid-cols-2 md:grid-cols-3";
  } else if(gridColumns === 4) {
    gridClass = "grid-cols-1 xs:grid-cols-2 lg:grid-cols-4";
  }

  return (
    <section id={sectionId ?? ``} className={`${className ?? ``} mt-14 mb-4 lg:my-4`}>
      <div className={`max-w-[1300px] mx-auto grid ${gridClass} gap-4`}>
        {gridItems && gridItems.map((item, index) => {
          if(item) {
          const { icon, title, content, background } = item;
          const color = background || "transparent";
          const bgColor = getBackgroundColor({ color });
          return (
            <div
              key={index}
              className={`${bgColor} group relative flex flex-col items-center justify-start pb-8 px-4 ${icon !== "none" ? "mt-[50px] pt-[70px]" : "pt-6"}`}
            >
              <div
                className={`absolute inset-0 w-full h-full bg-white opacity-0 group-hover:opacity-80 transition-all duration-300 z-10 mix-blend-overlay`}
              >
                <div
                  className={`w-full h-full opacity-65`}
                  style={{
                    background: "radial-gradient(#2C4B65, transparent)",
                  }}
                />
              </div>
              {icon !== "none" && (
                <div
                  className={`absolute -top-[50px] flex items-center justify-center w-[100px] h-[100px] rounded-full bg-med-blue z-20`}
                >
                  <Image
                    src={`/icons/${icon}-icon.svg`}
                    alt=""
                    className="w-[35%] h-[35%] object-contain"
                    width={150}
                    height={150}
                  />
                </div>
              )}
              {title && (
                <h3
                  className={`mt-4 mb-10 font-heading text-lx md:text-4xl text-white`}
                >
                  {title}
                </h3>
              )}
              {content && (
                <Content
                  className={`font-body text-md md:text-lg text-white`}
                  content={content}
                />
              )}
            </div>
          );}
        })}
      </div>
    </section>
  );
};

export default IconGrid;

IconGrid.fragments = {
  entry: gql`
    fragment IconGridFragment on Page_Flexiblecontent_Blocks_IconGrid {
      __typename
      sectionId
      columns
      gridItems {
        icon
        title
        content
        background
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
