import dynamic from "next/dynamic";
// Hero: (Non dynamic import) Above the fold content
import { Hero, SectionDivider, TextImage, Timeline, Impact, IconGrid, News } from "components";
// Dynamic Imports: Below the fold content
// import { FORM_BLOCK_FRAGMENT } from "components/Form/Form";
// const Form = dynamic(() => import("components/Form/Form"), {
//   ssr: true,
// });

import {
  Page_Flexiblecontent_Blocks,
  Page_Flexiblecontent_Blocks_Hero,
  // Page_Flexiblecontent_Blocks_Form,
  Page_Flexiblecontent_Blocks_SectionDivider,
  Page_Flexiblecontent_Blocks_TextImage,
  Page_Flexiblecontent_Blocks_Timeline,
  Page_Flexiblecontent_Blocks_Impact,
  Page_Flexiblecontent_Blocks_IconGrid,
  Page_Flexiblecontent_Blocks_News,
} from "graphql";
import { gql } from "@apollo/client";

interface BlocksProps {
  blocks: Page_Flexiblecontent_Blocks[];
}

interface BlockProps {
  block: Page_Flexiblecontent_Blocks;
}

const prefix = "Page_Flexiblecontent_Blocks_";

const Block = ({ block }: BlockProps) => {
  const { __typename } = block;

  let name = __typename && __typename.replace(prefix, "");

  switch (name) {
    // Hero
    case "Hero": {
      return <Hero {...(block as Page_Flexiblecontent_Blocks_Hero)} />;
    }
    // Form
    // case "Form": {
    //   return <Form {...(block as Page_Flexiblecontent_Blocks_Form)} />;
    // }
    // Section Divider
    case "SectionDivider": {
      return <SectionDivider {...(block as Page_Flexiblecontent_Blocks_SectionDivider)} />;
    }
    // Text-Image
    case "TextImage": {
      return <TextImage {...(block as Page_Flexiblecontent_Blocks_TextImage)} />;
    }
    // TimeLine
    case "Timeline": {
      return <Timeline {...(block as Page_Flexiblecontent_Blocks_Timeline)} />;
    }
    // Impact
    case "Impact": {
      return <Impact {...(block as Page_Flexiblecontent_Blocks_Impact)} />;
    }
    // Icon Grid
    case "IconGrid": {
      return <IconGrid {...(block as Page_Flexiblecontent_Blocks_IconGrid)} />;
    }
    // News
    case "News": {
      return <News {...(block as Page_Flexiblecontent_Blocks_News)} />;
    }

    default: {
      return null;
    }
  }
};

const Blocks = ({ blocks = [] }: BlocksProps): JSX.Element => {
  return (
    <>
      {blocks &&
        blocks.map((block, index) => (
          <Block block={block} key={`block-${index}`} />
        ))}
    </>
  );
};

export default Blocks;

Blocks.fragments = {
  entry: gql`
    fragment BlocksFragment on Page_Flexiblecontent {
      blocks {
        ... on Page_Flexiblecontent_Blocks_Hero {
          ...HeroFragment
        }
        ... on Page_Flexiblecontent_Blocks_SectionDivider {
          ...SectionDividerFragment
        }
        ... on Page_Flexiblecontent_Blocks_TextImage {
          ...TextImageFragment
        }
        ... on Page_Flexiblecontent_Blocks_Timeline {
          ...TimelineFragment
        }
        ... on Page_Flexiblecontent_Blocks_Impact {
          ...ImpactFragment
        }
        ... on Page_Flexiblecontent_Blocks_IconGrid {
          ...IconGridFragment
        }
        ... on Page_Flexiblecontent_Blocks_News {
          ...NewsFragment
        }
      }
    }
    ${Hero.fragments.entry}
    ${SectionDivider.fragments.entry}
    ${TextImage.fragments.entry}
    ${Timeline.fragments.entry}
    ${Impact.fragments.entry}
    ${IconGrid.fragments.entry}
    ${News.fragments.entry}
  `,
};
