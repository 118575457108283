type BgColorKey =
  | "transparent"
  | "light-grey"
  | "med-blue-grad"
  | "dark-blue-grad"
  | "red-blue-grad"
  | "red-yellow-grad"
  | "blue-light-grad"
  | "blue-yellow-grad"
  | "blue-green-grad"
  | "dark-blue-graphic"
  | "light-blue-graphic"
  | "red-graphic"
  | "dark-green-graphic"
  | string;

interface ColorProps {
  color?: BgColorKey;
}

const bgColorMap: Record<BgColorKey, string> = {
  "transparent": "bg-transparent",
  "light-grey": "bg-grey",
  "med-blue-grad": "bg-med-blue-grad",
  "dark-blue-grad": "bg-dark-blue-grad",
  "green-blue-dark-grad": "bg-green-blue-dark-grad",
  "blue-red-light-grad": "bg-blue-red-light-grad",
  "red-yellow-grad": "bg-red-yellow-grad",
  "blue-light-grad": "bg-blue-light-grad",
  "blue-yellow-grad": "bg-blue-yellow-grad",
  "blue-green-grad": "bg-blue-green-grad",
  "dark-blue-graphic": "bg-dark-blue-graphic",
  "light-blue-graphic": "bg-light-blue-graphic",
  "red-graphic": "bg-red-graphic",
  "dark-green-graphic": "bg-dark-green-graphic",
};

export const getBackgroundColor = ({
  color = "transparent",
}: ColorProps): string => {
  return bgColorMap[color];
};
