import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_TextImage } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import Image from "next/image";
import { Content } from "components/Content";
import {
  getBackgroundColor,
} from "utilities/getColor";
import { Button } from "components/Button";

interface TextImageProps extends Page_Flexiblecontent_Blocks_TextImage {
  className?: string;
}

const TextImage = ({
  className,
  sectionId,
  title,
  subtitle,
  content,
  image,
  imageSide,
  contentSplit,
  background,
  ctas,
  alignTitle,
  alignSubtitle,
  constrainContent,
  hasNoise,
}: TextImageProps) => {
  const color = background || "transparent";
  const bgColor = getBackgroundColor({ color });

  const { sourceUrl = "", altText, mediaDetails = {} } = image || {};
  const imageProps = {
    src: sourceUrl || "",
    alt: altText || title || "",
    width: mediaDetails?.width || 0,
    height: mediaDetails?.height || 0,
  };

  let splitImgClasses = "";
  let splitContClasses = "";
  if(contentSplit === "even") {
    splitImgClasses = "w-full md:w-2/4";
    splitContClasses = "w-full md:w-2/4";
  } else if(contentSplit === "60-40") {
    splitImgClasses = "w-full md:w-[60%]";
    splitContClasses = "w-full md:w-[40%]";
  } else if(contentSplit === "40-60") {
    splitImgClasses = "w-full md:w-[40%]";
    splitContClasses = "w-full md:w-[60%]";
  }

  const imgFlip = imageSide ? 'md:flex-row-reverse' : '';

  let txtColor = "";
  if (color === "transparent") {
    txtColor = "text-med-blue";
  } else {
    txtColor = "text-white";
  }


  return (
    <section
      id={sectionId ?? ``}
      className={`${className ?? ``} ${bgColor} my-4 overflow-hidden relative`}
    >
      {hasNoise && (
        <svg
          className="absolute top-0 left-0 right-0 bottom-0 mix-blend-overlay z-[11] opacity-65 pointer-events-none w-[100vw] h-auto"
          width="100%"
          height="100%"
          viewBox="0 0 2000 3000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <filter id="noiseFilter">
            <feTurbulence
              type="fractalNoise"
              baseFrequency="0.75"
              numOctaves="3"
              stitchTiles="stitch"
            ></feTurbulence>
          </filter>
          <rect width="100%" height="100%" filter="url(#noiseFilter)"></rect>
        </svg>
      )}
      <div
        className={`max-w-[1300px] mx-auto flex flex-col md:flex-row gap-8 items-center justify-center ${contentSplit} ${imgFlip}`}
      >
        <div className={`${splitContClasses} ${txtColor} p-4 pr-6`}>
          {title && (
            <h3
              className={`font-heading text-2xl md:text-5xl mb-6 ${alignTitle ? "text-center" : "text-center lg:text-left"}`}
            >
              {title}
            </h3>
          )}
          {subtitle && (
            <h4
              className={`font-body font-semibold text-2xl mb-4 ${alignSubtitle ? "text-center" : ""}`}
            >
              {subtitle}
            </h4>
          )}
          {content && (
            <Content
              className={`font-body text-xl [&>p]:text-md [&>p]:md:text-2xl [&>p]:font-medium text-center lg:text-left ${constrainContent ? "max-w-xl mx-auto" : ""}`}
              content={content}
            />
          )}
          {ctas && (
            <div
              className={`flex flex-col md:flex-row items-center gap-4 my-6 ${alignTitle ? "justify-center" : ""}`}
            >
              {ctas.map((cta, index) => (
                <Button
                  key={index}
                  className={`flex items-center gap-2 w-fit font-bold`}
                  type={cta?.type ?? "default"}
                  href={cta?.link?.url ?? ""}
                  target={cta?.link?.target ?? "_self"}
                >
                  {cta?.link?.title}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className={`${splitImgClasses}`}>
          <Image
            {...imageProps}
            // className={`${color !== "transparent" && "mix-blend-luminosity"}`}
          />
        </div>
      </div>
    </section>
  );
};

export default TextImage;

TextImage.fragments = {
  entry: gql`
    fragment TextImageFragment on Page_Flexiblecontent_Blocks_TextImage {
      __typename
      sectionId
      title
      subtitle
      content
      ctas {
        link {
          title
          target
          url
        }
        type
      }
      imageSide
      contentSplit
      alignTitle
      alignSubtitle
      constrainContent
      hasNoise
      background
      image {
        ...MediaItemFragment
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
