import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_SectionDivider } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { FeaturedImage } from "components/FeaturedImage";
import { Content } from "components/Content";
import { getBackgroundColor } from "utilities/getColor";

import React, { useRef } from "react";

interface SectionDividerProps
  extends Page_Flexiblecontent_Blocks_SectionDivider {
  className?: string;
}

const SectionDivider = ({
  className,
  sectionId,
  sectionTitle,
  sectionSubtitle,
  sectionBackground,
  subtitleSmaller,
}: SectionDividerProps) => {
  const color = sectionBackground || "transparent";
  const bgColor = getBackgroundColor({ color });
  const sectionRef = useRef<HTMLElement>(null);

  let txtColor = "";
  if (color === "transparent") {
    txtColor = "text-black";
  } else {
    txtColor = "text-white";
  }

  const goToNextSection = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // Find the next sibling element with the class 'section-divider'
    let nextSection = sectionRef.current?.nextElementSibling;
    while (nextSection && !nextSection.classList.contains("section-divider")) {
      nextSection = nextSection.nextElementSibling;
    }

    if (nextSection) {
      const topPosition =
        nextSection.getBoundingClientRect().top + window.pageYOffset - 88;
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <section
      id={sectionId ?? ``}
      className={`${className || ``} ${bgColor} relative pb-8 section-divider`.trim()}
      ref={sectionRef}
    >
      <div className={`max-w-[1300px] mx-auto text-center pt-5 pb-0 px-4`}>
        {sectionTitle ? (
          <h2
            className={`${txtColor} font-body ${subtitleSmaller ? "text-2xl" : "text-lg"} mb-1`}
            dangerouslySetInnerHTML={{
              __html: sectionTitle,
            }}
          />
        ) : null}
        {sectionSubtitle ? (
          <h3
            className={`${txtColor} font-heading ${subtitleSmaller ? "text-lg" : "text-3xl"}`}
            dangerouslySetInnerHTML={{
              __html: sectionSubtitle,
            }}
          />
        ) : null}
      </div>
      <a href="#" onClick={(e) => goToNextSection(e)}>
        <svg
          className={`absolute left-1/2 -translate-x-1/2 -bottom-[39px] z-20`}
          width="68"
          height="68"
          viewBox="0 0 78 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="39" cy="39" r="39" fill="#2C4B65" />
          <path
            d="M40.0839 46.815L46.7573 40.8829C47.073 40.5728 47.0703 40.1225 46.8095 39.8344C46.5486 39.5462 46.0491 39.5213 45.761 39.7822L40.3272 44.6136L40.3272 32.1701C40.3272 31.7605 39.9952 31.4286 39.5857 31.4286C39.1761 31.4286 38.8442 31.7605 38.8442 32.1701L38.8442 44.6136L33.4104 39.7822C33.1222 39.5213 32.6319 39.5548 32.3619 39.8344C32.0807 40.1254 32.1259 40.6221 32.414 40.8829L39.0875 46.815C39.4538 47.077 39.7641 47.0458 40.0839 46.815Z"
            fill="#B2D33E"
          />
        </svg>
      </a>
    </section>
  );
};

export default SectionDivider;

SectionDivider.fragments = {
  entry: gql`
    fragment SectionDividerFragment on Page_Flexiblecontent_Blocks_SectionDivider {
      __typename
      sectionId
      sectionTitle
      sectionSubtitle
      sectionBackground
      subtitleSmaller
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
