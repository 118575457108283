import { Page_Flexiblecontent_Blocks_Impact } from "graphql";
import clsx from "clsx";
import { Content } from "components/Content";
import { Button } from "components/Button";
import Image from "next/image";
import useMediaQuery from "utilities/useMediaQuery";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

interface ImageImpProps extends Page_Flexiblecontent_Blocks_Impact {
  className?: string;
}

const ImageImpact = ({
  className,
  innerSectionTitle,
  innerSectionContent,
  innerSectionCtas,
  verticalText,
  imageBlocks,
}: ImageImpProps) => {
    const isMobile = useMediaQuery("(max-width: 968px)");

  return (
    <div
      className={`${className ?? ``} mt-14 lg:mt-0 max-w-[1300px] mx-auto flex flex-col min-[968px]:flex-row gap-3 items-center justify-center`}
    >
      <div
        className={`w-full flex flex-col gap-4 justify-center min-[968px]:w-[38%] px-4 text-center text-med-blue`}
      >
        {innerSectionTitle && (
          <h3 className={`font-heading text-2xl md:text-5xl mb-6`}>
            {innerSectionTitle}
          </h3>
        )}
        {innerSectionContent && (
          <Content
            className={`font-body text-md md:text-lg`}
            content={innerSectionContent}
          />
        )}
        {innerSectionCtas && (
          <div
            className={`flex flex-col md:flex-row items-center justify-center gap-4 my-6`}
          >
            {innerSectionCtas.map((cta, index) => (
              <Button
                key={index}
                className={`flex items-center gap-2 w-fit capitalize min-[300px]:text-dark-blue`}
                type={cta?.type ?? "default"}
                href={cta?.link?.url ?? ""}
                target={cta?.link?.target ?? "_self"}
              >
                {cta?.link?.title}
              </Button>
            ))}
          </div>
        )}
      </div>
      <div
        className={`relative origin-bottom w-full min-[968px]:w-[4%] px-6 py-2 min-[968px]:h-full min-[968px]:self-end flex items-center justify-center min-[968px]:justify-end`}
      >
        <div
          className={`min-[968px]:absolute w-max flex flex-row items-center justify-center gap-2 -mr-5 -mt-3`}
        >
          <div className={`bg-light-blue text-white px-3 py-1 rounded-full`}>
            {verticalText}
          </div>
          <div
            className={`text-light-blue border-[1px] border-light-blue p-1 rounded-full rotate-45`}
          >
            {isMobile ? <FaArrowDown /> : <FaArrowUp />}
          </div>
        </div>
      </div>
      <div
        className={`relative w-full min-[968px]:w-[58%] grid grid-cols-2 sm:grid-cols-3 gap-4`}
      >
        {imageBlocks &&
          imageBlocks.map((imageBlock, index) => {
            const { image, cause, causeLink } = imageBlock || {};
            const { sourceUrl = "", altText, mediaDetails = {} } = image || {};
            const imageProps = {
              src: sourceUrl || "",
              alt: altText || innerSectionTitle || "",
              width: mediaDetails?.width || 0,
              height: mediaDetails?.height || 0,
            };
            return causeLink?.url ? (
              <a
                key={"cause" + index}
                href={causeLink?.url ?? ""}
                target={causeLink?.target ?? "_self"}
              >
                <div className={`relative flex items-center justify-center`}>
                  <Image {...imageProps} className={`relative`} />
                  <div
                    className={`absolute bottom-2 py-2 px-4 max-w-[95%] rounded-full bg-dark-blue text-white font-body text-center`}
                  >
                    {cause}
                  </div>
                </div>
              </a>
            ) : (
              <div
                key={"cause" + index}
                className={`relative flex items-center justify-center`}
              >
                <Image {...imageProps} className={`relative`} />
                <div
                  className={`absolute bottom-2 py-2 px-4 max-w-[95%] rounded-full bg-dark-blue text-white font-body text-center`}
                >
                  {cause}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ImageImpact;
