import React from 'react'

const NoiseFilter = () => {
  return (
    <svg
      className={`absolute top-0 left-0 right-0 bottom-0 mix-blend-overlay z-[11] opacity-65 pointer-events-none w-[100vw] h-auto`}
      width="100%"
      height="100%"
      viewBox="0 0 2000 3000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <filter id="noiseFilter">
        <feTurbulence
          type="fractalNoise"
          baseFrequency="0.75"
          numOctaves="3"
          stitchTiles="stitch"
        />
      </filter>
      <rect width="100%" height="100%" filter="url(#noiseFilter)" />
    </svg>
  );
};

export default NoiseFilter