import { Page_Flexiblecontent_Blocks_Impact } from "graphql";
import clsx from "clsx";
import { Content } from "components/Content";
import { Button } from "components/Button";
import Image from "next/image";
import useMediaQuery from "utilities/useMediaQuery";
import { getBackgroundColor } from "utilities/getColor";
import CountUpLibrary from "react-countup";

interface CountProps extends Page_Flexiblecontent_Blocks_Impact {
  className?: string;
}

const CountImpact = ({
  className,
  innerSectionTitle,
  innerSectionContent,
  innerSectionCtas,
  calloutContent,
  calloutBackground,
  countupBlocks,
}: CountProps) => {
  const blockCount = countupBlocks?.length ?? 0;
  let gridCols = ``;
  if (blockCount === 1) {
    gridCols = `grid-cols-1 min-[968px]:grid-cols-3`;
  } else if (blockCount === 2) {
    gridCols = `grid-cols-2`;
  } else if (blockCount === 3) {
    gridCols = `grid-cols-2 min-[968px]:grid-cols-3`;
  } else if (blockCount === 4) {
    gridCols = `grid-cols-2 min-[968px]:grid-cols-3`;
  } else if (blockCount === 5) {
    gridCols = `grid-cols-2 min-[968px]:grid-cols-3`;
  } else if (blockCount === 6) {
    gridCols = `grid-cols-2 min-[968px]:grid-cols-4`;
  }

  return (
    <div
      className={`${className ?? ``} max-w-[1300px] mx-auto grid ${gridCols} gap-4`}
    >
      {calloutContent && (
        <div
          className={`order-1 min-[968px]:order-2 max-[968px]:col-span-2 w-full bg-${calloutBackground} text-${calloutBackground !== "none" ? "white" : "black"} p-4 text-center`}
        >
          <Content
            className={`font-body text-md min-[968px]:text-lg pt-6`}
            content={calloutContent}
          />
        </div>
      )}
      <div
        className={`order-last min-[968px]:order-5 max-[968px]:col-span-2 w-full flex flex-col gap-4 justify-center px-4 text-center text-med-blue p-4`}
      >
        {innerSectionTitle && (
          <h3 className={`font-heading text-2xl md:text-4xl`}>
            {innerSectionTitle}
          </h3>
        )}
        {innerSectionContent && (
          <Content
            className={`font-body text-md md:text-lg my-4`}
            content={innerSectionContent}
          />
        )}
        {innerSectionCtas && (
          <div className={`flex flex-row items-center justify-center gap-4`}>
            {innerSectionCtas.map((cta, index) => (
              <Button
                key={index}
                className={`flex items-center gap-2 w-fit`}
                type={cta?.type ?? "default"}
                href={cta?.link?.url ?? ""}
                target={cta?.link?.target ?? "_self"}
              >
                {cta?.link?.title}
              </Button>
            ))}
          </div>
        )}
      </div>
      {countupBlocks &&
        countupBlocks.map((block, index) => {
          const {
            isDollarAmount,
            isAbbreviated,
            number,
            amountFor,
            background,
          } = block || {};
          const color = background || "transparent";
          const bgColor = getBackgroundColor({ color });
          let txtColor = "";
          if (color === "transparent") {
            txtColor = "text-med-blue";
          } else {
            txtColor = "text-white";
          }

          let blockOrder = "";
          if (index === 0) {
            blockOrder = "order-2 min-[968px]:order-1";
          } else if (index === 1) {
            blockOrder = "order-3";
          } else if (index === 2) {
            blockOrder = "order-4";
          } else if (index === 3) {
            blockOrder = "order-5 min-[968px]:order-6";
          } else if (index === 4) {
            blockOrder = "order-6 min-[968px]:order-7";
          } else if (index === 5) {
            blockOrder = "order-7 min-[968px]:order-8";
          } else if (index === 6) {
            blockOrder = "order-8 min-[968px]:order-9";
          }

          const str = number?.toString() || "";
          const numArray = str?.split("");
          const numCount = numArray?.length ?? 0;

          let abbriv = "";
          let abbrNum = 0;
          let decNum = "";
          if (numCount > 3 && numCount <= 6) {
            abbriv = "K";
            abbrNum = Number(str.slice(0, -3));
          } else if (numCount > 6 && numCount <= 9) {
            abbriv = "M";
            abbrNum = Number(str.slice(0, -6));
          } else if (numCount > 9) {
            abbriv = "B";

            decNum = str.slice(0, -6).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            // Remove leading and trailing zeros
            abbrNum = Number(decNum.replace(/^0+(\d)|(\d)0+$/gm, "$1$2"));
          }

          const finalNum = isAbbreviated ? abbrNum : number;

          return (
            <div
              key={index}
              className={`${blockOrder} ${bgColor} w-full py-4 px-4 ${txtColor} text-center flex flex-col items-center justify-center min-h-[130px] group relative`}
            >
              <div
                className={`absolute inset-0 w-full h-full bg-white opacity-0 group-hover:opacity-80 transition-all duration-300 z-10 mix-blend-overlay`}
              >
                <div
                  className={`w-full h-full opacity-65`}
                  style={{ background: "radial-gradient(#2C4B65, transparent)" }}
                />
              </div>
              <CountUpLibrary
                start={0}
                end={finalNum ?? 100000}
                duration={1.5}
                decimals={numCount > 9 ? 1 : 0}
                decimal={numCount > 9 ? "." : ""}
                enableScrollSpy
                scrollSpyDelay={0.2}
                prefix={isDollarAmount ? "$" : ""}
                suffix={isAbbreviated ? abbriv : ""}
                className={`font-heading text-2xl md:text-5xl mb-4`}
              />
              <p className={`font-body text-md md:text-lg`}>{amountFor}</p>
            </div>
          );
        })}
    </div>
  );
};

export default CountImpact;
