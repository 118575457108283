import Link from "next/link";

interface ButtonProps {
  href?: string;
  target?: string;
  children: React.ReactNode;
  className?: string;
  type:
    | "btn-red"
    | "btn-green"
    | "btn-med-blue"
    | "btn-timeline"
    | "green-text-link"
    | "blue-text-link"
    | "white-text-link"
    | "donate"
    | "donate-white"
    | "form-submit"
    | "default"
    | string;
  onClick?: () => void;
  disabled?: boolean;
  noArrow?: boolean;
}

const styleLoader = (type: string) => {
  switch (type) {
    case "donate-white":
      return `w-max rounded-full bg-white border-green py-2 px-6 font-bold text-green font-body uppercase
      hover:bg-green hover:text-white transition duration-300 ease-in-out flex flex-row gap-2 items-center`;
    case "donate":
      return `w-max rounded-full bg-green border-green border-2 py-2 px-6 font-bold text-white font-body uppercase transition duration-300 ease-in-out hover:shadow-lg text-dark-blue hover:text-green hover:bg-white flex flex-row gap-2 items-center`;
    case "btn-red":
      return `w-max rounded-full border-red border-2 bg-red py-2 px-6 font-semibold text-white font-body capitalize transition duration-300 ease-in-out hover:bg-white hover:shadow-md hover:text-red`;
    case "btn-green":
      return `w-max rounded-full border-green border-2 bg-green py-2 px-6 font-semibold text-white font-body uppercase transition duration-300 ease-in-out hover:bg-white hover:shadow-md hover:text-green`;
    case "btn-med-blue":
      return `w-max rounded-full border-med-blue border-2 bg-med-blue py-2 px-6 font-semibold text-white font-body uppercase transition duration-300 ease-in-out hover:bg-white hover:shadow-md hover:text-med-blue`;
    case "btn-timeline":
      return `w-max rounded-full border-white border-2 bg-[#778089] bg-opacity-60 py-2 px-6 font-semibold text-white font-body uppercase transition duration-300 ease-in-out hover:bg-white hover:shadow-md hover:text-med-blue min-w-[190px]`;
    case "green-text-link":
      return `text-green font-body transition duration-300 ease-in-out font-bold flex flex-row gap-2 items-center`;
    case "blue-text-link":
      return `text-med-blue font-body transition duration-300 ease-in-out font-bold flex flex-row gap-2 items-center`;
    case "white-text-link":
      return `text-white font-body transition duration-300 ease-in-out hover:font-bold flex flex-row gap-2 items-center`;
    case "form-submit":
      return `text-med-blue font-body text-lg py-2 px-6 font-semibold transition duration-300 ease-in-out w-max rounded-full hover:text-white hover:bg-med-blue flex flex-row gap-2 items-center`;
    default:
      return `rounded-full border-white border-2 bg-grey py-2 px-6 font-bold text-white font-body uppercase transition duration-300 ease-in-out hover:bg-white hover:shadow-md hover:text-med-blue`;
  }
};

const Button = ({
  href,
  target,
  children,
  className,
  type,
  disabled,
  noArrow,
  ...props
}: ButtonProps) => {
  const getArrowColor = (): string => {
    switch (type) {
      case "green-text-link":
        return "#B2D33E";
      case "blue-text-link":
        return "#1C2D5A";
      case "white-text-link":
        return "#FFFFFF";
      default:
        return "#000000";
    }
  };

  if (href && type !== "donate" && type !== "donate-white") {
    return (
      <Link
        className={`${styleLoader(type)} ${className}`}
        role="button"
        href={href}
        target={target}
        {...props}
      >
        {children}{" "}
        {noArrow
          ? null
          : (type === "green-text-link" ||
              type === "blue-text-link" ||
              type === "white-text-link") && (
              <svg
                className={`w-[9px]`}
                width="13"
                height="19"
                viewBox="0 0 13 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2L10 9L1 17"
                  stroke={getArrowColor()}
                  strokeWidth="3"
                />
              </svg>
            )}
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      className={`${styleLoader(type)} ${className}`}
      {...props}
    >
      {children}{" "}
      {noArrow ? null : (
        <svg
          className={`w-[9px]`}
          width="13"
          height="19"
          viewBox="0 0 13 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 2L10 9L1 17" stroke={getArrowColor()} strokeWidth="3" />
        </svg>
      )}
    </button>
  );
};
export default Button;
