import { gql } from "@apollo/client";
import { Page_Flexiblecontent_Blocks_Timeline } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import React from "react";
import Image from "next/image";
import { Content } from "components/Content";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useRef } from "react";
import { FaLessThan, FaPlus } from "react-icons/fa";
import {
  getBackgroundColor,
} from "utilities/getColor";
import { Button } from "components/Button";
import { NoiseFilter } from "components/NoiseFilter";

interface TimelineProps extends Page_Flexiblecontent_Blocks_Timeline {
  className?: string;
}

const variants = {
  openModal: { display: "flex" },
  closedModal: { display: "none" },
};


const Timeline = ({
  className,
  sectionId,
  timelineBlocks,
}: TimelineProps) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(timelineBlocks && timelineBlocks[0]);

  const [hover, setHover] = useState(-1);

  const popupImageProps = {
    src: selectedTab?.popupImage?.sourceUrl || "",
    alt: selectedTab?.popupImage?.altText || selectedTab?.dateRange || "",
    width: selectedTab?.popupImage?.mediaDetails?.width || 0,
    height: selectedTab?.popupImage?.mediaDetails?.height || 0,
  };
  return (
    <section id={sectionId ?? ``} className={`${className ?? ``}`}>
      <div
        className={`min-[985px]:relative grid grid-cols-2 md:grid-cols-4 my-4`}
      >
        {timelineBlocks
          ? React.Children.toArray(
              timelineBlocks.map((block: any, index: number) => {
                const {
                  dateRange,
                  popupContent,
                  cardBackground,
                  cardImage,
                  popupImage,
                } = block;

                const color = cardBackground || "transparent";
                const bgColor = getBackgroundColor({ color });

                let adjustedBgClass = "";
                if (color === "red-yellow-grad") {
                  adjustedBgClass = "bg-red-yellow-grad-timeline";
                } else if (color === "blue-yellow-grad") {
                  adjustedBgClass = "bg-blue-yellow-grad-timeline";
                } else if (color === "blue-light-grad") {
                  adjustedBgClass = "bg-blue-light-grad-timeline";
                } else if (color === "blue-green-grad") {
                  adjustedBgClass = "bg-blue-green-grad-timeline";
                }

                let txtColor = "";
                if (color === "transparent") {
                  txtColor = "text-black";
                } else {
                  txtColor = "text-white";
                }

                const imageProps = {
                  src: cardImage?.sourceUrl || "",
                  alt: cardImage?.altText || dateRange || "",
                  width: cardImage?.mediaDetails?.width || 0,
                  height: cardImage?.mediaDetails?.height || 0,
                };

                return (
                  <>
                    <motion.div
                      key={index}
                      className={`${adjustedBgClass}`}
                      layout
                    >
                      <motion.div>
                        <div className={`relative overflow-hidden`}>
                          <NoiseFilter />
                          <div
                            className={`absolute w-full h-full flex justify-center items-center z-[2]`}
                          >
                            {dateRange ? (
                              <span
                                onMouseEnter={() => {
                                  setHover(index);
                                }}
                                onMouseLeave={() => {
                                  setHover(-1);
                                }}
                              >
                                <Button
                                  className={``}
                                  type={`btn-timeline`}
                                  noArrow
                                  onClick={() => {
                                    setSelectedTab(block);
                                    setOpen(true);
                                  }}
                                >
                                  {/* {dateRange} */}
                                  {hover === index ? "Learn More +" : dateRange}
                                </Button>
                              </span>
                            ) : null}
                          </div>
                          <Image
                            {...imageProps}
                            className={`relative`}
                          />
                        </div>
                      </motion.div>
                    </motion.div>
                  </>
                );
              })
            )
          : null}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              key={selectedTab ? selectedTab.dateRange : "empty"}
              initial="closedModal"
              animate={isOpen ? "openModal" : "closedModal"}
              variants={variants}
              transition={{
                duration: "300ms",
                ease: "easeInOut",
              }}
              className={`absolute w-full min-[985px]:h-full max-[985px]:mt-4 z-[3] items-center justify-center`}
            >
              <div
                className={`relative max-h-full max-w-[95%] lg:max-w-[1024px] flex items-center justify-center bg-white`}
              >
                {selectedTab ? (
                  <div
                    className={`grid md:grid-cols-[40%_60%] items-center justify-center`}
                  >
                    <Image
                      {...popupImageProps}
                      className={`h-full w-full object-cover`}
                    />
                    <div className={` flex flex-col h-full py-8 px-16`}>
                      <Button
                        className={`flex items-center gap-4 mb-8 w-fit font-bold`}
                        type={`green-text-link`}
                        onClick={() => setOpen(false)}
                        noArrow
                      >
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M6 1L1 6L6 11" stroke="#B2D33E" />
                        </svg>
                        Back To Timeline
                      </Button>
                      <div className={``}>
                        {selectedTab.dateRange ? (
                          <h3
                            className={`font-heading text-2xl md:text-5xl text-med-blue mb-3`}
                          >
                            {selectedTab.dateRange}
                          </h3>
                        ) : null}
                        <Content
                          content={selectedTab.popupContent}
                          className={`font-body leading-[158.3%]`}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default Timeline;

Timeline.fragments = {
  entry: gql`
    fragment TimelineFragment on Page_Flexiblecontent_Blocks_Timeline {
      __typename
      sectionId
      timelineBlocks {
        dateRange
        popupContent
        cardBackground
        cardImage {
          ...MediaItemFragment
        }
        popupImage {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
