import React, { useState, useEffect } from "react";
import { Main } from "features/Main";
import {
  AcfLink,
  Acf_GoogleMap,
  MediaItem,
  MenuItem,
  PostTypeSeo,
  RootQueryToMenuItemConnection,
} from "graphql";
import { SEO } from "features/SEO";
import { Header, Footer } from "components";
import { Montserrat } from "next/font/google";
import localFont from "next/font/local";
import { IntroAnimation } from "components/IntroAnimation";

const roboto = Montserrat({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600", "700", "900"],
  variable: "--font-body",
});
const yesevaOne = localFont({
  src: "../../pages/YesevaOne-Regular.woff2",
  variable: "--font-heading",
});

export interface LayoutProps {
  children: React.ReactNode;
  headerMenuItems: RootQueryToMenuItemConnection;
  footerMenuItems: RootQueryToMenuItemConnection;
  title: string;
  description: string;
  logo: MediaItem;
  logoAlt: MediaItem;
  logoWhite: MediaItem;
  seo: PostTypeSeo;
  cta: AcfLink;
  ctaFooter: AcfLink;
  phoneNumber: AcfLink;
  address: Acf_GoogleMap;
  email: AcfLink;
}

const Layout = ({
  children,
  headerMenuItems,
  footerMenuItems,
  cta,
  ctaFooter,
  title,
  description,
  seo,
  logo,
  logoAlt,
  logoWhite,
  phoneNumber,
  address,
  email,
}: LayoutProps) => {
  return (
    <>
      <SEO seo={seo} />
      <div className={`${roboto.variable} ${yesevaOne.variable}`}>
        <IntroAnimation />
      </div>
      <div
        className={`${roboto.variable} ${yesevaOne.variable} font-body relative z-10 bg-white`}
      >
        <Header
          menuItems={headerMenuItems.nodes}
          logo={logo}
          logoAlt={logoAlt}
          cta={cta}
        />
        <Main className={`main`}>{children}</Main>
        <Footer
          menuItems={footerMenuItems.nodes}
          ctaFooter={ctaFooter}
          phoneNumber={phoneNumber}
          logo={logo}
          address={address}
          email={email}
        />
      </div>
    </>
  );
};

export default Layout;
